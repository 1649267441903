import { useParams } from "react-router-dom";
import { useEffect } from "react";

export default function SingleTreatment() {

    const { treatmentName } = useParams();
    useEffect(() => {
        if (document.getElementById(treatmentName)) {
            document.getElementById(treatmentName).scrollIntoView({ behavior: "smooth" });
        }
    }, [treatmentName])

    return (
        <>



        </>
    );
}
