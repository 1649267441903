import NavigationMenu from "../nav/nav";
import { IsScroll } from "../scroll";
import styled from "styled-components";
import { HStack, Show } from '@chakra-ui/react';
import DrawerMenu from "../drawer/drawerMenu";
import { useLocation } from "react-router-dom";
import HomeLinkLogo from "../homeLinkLogo";
import SNSLinks from "../snsLinks/snsHeader";


const StyledHeader = styled.header`
  position: fixed;
  top: ${({ $isPosition }) => `${$isPosition}` === 'top' ? 0 : 'none'};
  width: 100%;
  height:80px;
  box-shadow: ${({ $isScrolled }) => $isScrolled === 'true' ? '0 2px 4px gray' : 'none'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: ${({ $isScrolled }) => $isScrolled === 'true' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0)'};
  z-index:10;
`;


export default function Header({ position }) {
  const path = useLocation().pathname;
  // const isScroll = path==='/' ?  IsScroll() :true;
  const scroll = IsScroll({ scrollY: 300 });
  const isPage = true;
  const isScroll = path === '/' ? scroll : isPage;
  return (
    <StyledHeader
      $isPosition={position}
      $isScrolled={isScroll.toString()}
    >
      <HStack
        gap={['1.5em', '1.5em', '1.5em', '2.5em']}
      >
        <HomeLinkLogo logoPath={'normal'} />
        <SNSLinks />
      </HStack>
      <Show above='lg'>
        <NavigationMenu color={'black'} isDropDown={true} />
      </Show>
      <Show below='lg'>
        <DrawerMenu />
      </Show>


    </StyledHeader>
  );
}
