import { Box, HStack, Hide, Icon, Heading, Link, Text } from '@chakra-ui/react';
import { LINEInfo, TellPhoneNums } from '../../data/Infomations';
import { FaLine, FaPhone } from "react-icons/fa";

export default function ReservationBanner() {

    return (
        <>
            <Box
                borderRadius={'15px'}
                border={['3px solid #f8b73d']}
                p={['10px']}
                background={'rgba(248,183,61,0.05)'}
            >
                <Heading
                    color={['logoColor.y']}
                    fontSize={['lg', 'lg,', '2xl']}
                    fontFamily={['"Noto Sans JP"']}
                    margin={['0 auto 15px auto']}
                    w={'fit-content'}
                    textAlign={'center'}
                >
                    ご予約はコチラから
                </Heading>
                <HStack
                    gap={['1em']}
                    justifyContent={'center'}
                >
                    <HStack
                        gap={['0.4em']}

                    >
                        <Link
                            href={LINEInfo.friendUrl}
                            isExternal
                            transition='all 0.3s'
                            _hover={{ opacity: 0.3 }}
                        >
                            <Icon
                                as={FaLine}
                                color={'#06C755'}
                                w={['35px', '35px', '35px', '35px', '40px']}
                                h={['35px', '35px', '35px', '35px', '40px']}
                                verticalAlign={'middle'}
                            />
                            <Hide below='md'>
                                <Text
                                    display={'inline-block'}
                                    fontSize={['14px', '14px', '16px', '18px', '20px']}
                                    fontWeight={['bold']}
                                    verticalAlign={'middle'}
                                    color={'#06C755'}

                                >
                                    公式LINE
                                </Text>
                            </Hide>
                        </Link>
                    </HStack>

                    <HStack
                        gap={['0.4em']}
                    >
                        <Link
                            href={TellPhoneNums.link}
                            isExternal
                            transition='all 0.3s'
                            _hover={{ opacity: 0.3 }}
                        >
                            <Icon
                                as={FaPhone}
                                w={['22px', '22px', '20x', '22x', '25px']}
                                h={['22px', '22px', '20x', '22x', '25px']}
                                verticalAlign={'middle'}
                            />
                            <Hide below='md'>
                                <Text
                                    display={'inline-block'}
                                    fontSize={['14px', '16px', '18px', '20px', '22px']}
                                    fontWeight={['bold']}
                                    verticalAlign={'middle'}

                                >
                                    {TellPhoneNums.num}
                                </Text>
                            </Hide>

                        </Link>
                    </HStack>

                </HStack>
            </Box>

        </>
    )
}