import { HStack, Icon, Text } from "@chakra-ui/react"
import { FaCircle } from "react-icons/fa";

export const ServiceFlowCard = ({ service, id }) => {
    return (
        <HStack
            w={['auto']}
        >
            <HStack
                position='relative'
                marginRight={['10px']}
            >
                <Icon
                    as={FaCircle}
                    position='absolute'
                    zIndex='1'
                    top='-1px'
                    left='0'
                    color='logoColor.g'
                    w={['30px']}
                    h={['30px']}

                />
                <Text
                    w={['30px']}
                    h={['30px']}
                    textAlign='center'
                    verticalAlign={['bottom']}
                    color={['white']}
                    zIndex='2'
                >{id + 1}</Text>
            </HStack>
            <Text
                fontSize={['sm', 'sm', 'md']}>
                {service.name}
            </Text>
        </HStack>
    )
}