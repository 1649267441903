export const SetOptionNamesToPost = (post, categories = [], tags = []) => {

    const newPost = { ...post };

    if (tags.length > 0) {
        const tagIds = [...post.tags];
        const tagNames = [];
        for (const tag of tags) {
            for (const categoryId of tagIds) {
                if (tag.id === categoryId) {
                    tagNames.push(tag.name);
                    break
                }
            }
        }
        newPost.tagNames = tagNames
    }


    if (categories.length > 0) {
        const categoryIds = [...post.categories];
        const categoryNames = [];
        for (const category of categories) {
            for (const categoryId of categoryIds) {
                if (category.id === categoryId) {
                    categoryNames.push(category.name);
                    break
                }
            }
        }
        newPost.categoryNames = categoryNames

    }
    return newPost
}

export const SetOptionNamesToPosts = (posts, categories = [], tags = []) => {
    const newPosts = posts.map(post => {
        const newPost = SetOptionNamesToPost(post, categories, tags)
        return newPost
    })
    return newPosts
}
