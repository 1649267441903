import { VStack } from "@chakra-ui/react"
import Iframe from "react-iframe"

export const MapIframe = () => {

    return (
        <VStack
            className="googlemap_wrapper"
            position='relative'

            width='100%'
            overflow='hidden'
            borderRadius='8px'
            border='3px inset #a0a0a0'
            borderColor='#eeeeee'
        >
            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.5441269286275!2d139.6833706!3d35.762008099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed2833776d21%3A0x491fd41100e88e8c!2z44CSMTc0LTAwNzEg5p2x5Lqs6YO95p2_5qmL5Yy65bi455uk5Y-w77yT5LiB55uu77yS77yQ4oiS77yYIOefs-eUsOODk-ODqw!5e0!3m2!1sja!2sjp!4v1717512847160!5m2!1sja!2sjp"
                width="600"
                height="450"
                style={{ border: 0 }}
                // loading="lazy"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            // referrerpolicy="no-referrer-when-downgrade"
            // title="googlemap" 
            />
        </VStack>
    )
}