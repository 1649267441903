import { Box, Heading, Img, Text, VStack } from "@chakra-ui/react"
import ReservationBanner from "../banner/ReservationBanner"

export const ServiceStep = ({ service, id }) => {
    return (
        <>
            <Box
                id={service.id}
                w={['100%']}
                alignItems={['start']}
                borderLeft={['3px gray solid', 'none', 'none']}
                display={['block', 'flex']}
                marginTop={['-80px']}
                paddingTop={['80px']}


            >
                <Box
                    paddingTop={['20px']}
                    paddingRight={['10px']}
                    paddingLeft={['20px', '0']}
                >
                    <Heading
                        fontFamily={['serif']}
                        fontSize={['3xl', '4xl']}
                        color={['logoColor.g']}
                    >
                        {`Step${id + 1}`}
                    </Heading>
                </Box>
                <Box
                    position={['relative']}
                    borderLeft={['none', '3px gray solid']}
                    w={['100%']}
                    p={['10px 10px 20px', '20px 20px']}
                    _before={{
                        content: '""',
                        w: '12px',
                        h: '12px',
                        background: 'var(--logoColor-g)',
                        position: 'absolute',
                        borderRadius: '100%',
                        top: { base: '-30px', sm: '31px' },
                        left: '-7px'
                    }}
                >
                    <Box>
                        <Heading
                            color={['logoColor.g']}
                            fontSize={['lg', 'lg,', '2xl']}
                            fontFamily={['"Noto Sans JP"']}
                        >
                            {service.name}
                        </Heading>
                    </Box>
                    <Box>
                        <Text>{service.content}</Text>
                    </Box>
                    <VStack
                        w={['100%']}
                        justifyContent={['center']}
                        p={['20px 0', '30px 0']}

                    >
                        <Img src={`${process.env.PUBLIC_URL}/images/serviceflow/${service.id}.jpg`}
                            alt={service.name}
                            maxHeight={['250px']} />
                        {service.name === 'ご予約(LINEまたはお電話)' ? <ReservationBanner /> : ""}
                    </VStack>
                </Box>

            </Box >
        </>
    )
}