import FullSlideShow from '../../components/slideshow/fullScreenSlideshow'
import { NewsList } from '../../components/news/NewsList';
import { Box, Text, VStack } from '@chakra-ui/react';
import { HeadingLogo } from '../../components/heading/HeadingLogo';
import { BackgroundWrapper } from '../../components/wrapper/BackgroundWrapper';
import { TreatmentsLists } from '../../components/treatmenstList/TreatmentsLists';
import { ServiceFlowOverview } from '../../components/serviceflow/ServiceFlowOverview';
import { GreetingHome } from '../../components/greeting/GreetingHome'

export default function HomePage() {
    const StyledText = ({ children, ...rest }) => {
        return (
            <Box
                margin={['50px 0']}
                textAlign='center'
                fontFamily='"Zen Maru Gothic"'
                fontSize={["md", "lg", "lg"]}
                fontWeight={["medium"]}
                lineHeight={['50px']}
                {...rest}
            >
                {children}

            </Box>

        )
    }

    const StyledBox = ({ children, ...rest }) => {
        return (
            <Box
                padding={['30px 0 50px']}
                position='relative'
                w={['100%']}
                {...rest}
            >
                {children}
            </Box>
        )
    }

    return (
        <>
            <FullSlideShow />

            <VStack alignItems='start'
                w={['100%']}
                h={['auto']}
                gap='0'
            >
                <Box
                    margin='30px 0px'
                    w={['100%']}
                >
                    <StyledText h="50px">
                        <Text lineHeight={["40px"]}>痛みの根本原因を追求し、本当の治療を、</Text>
                        <Text lineHeight={["40px"]}>経験と情熱であなたの健康を支える接骨院</Text>
                    </StyledText>
                    <HeadingLogo
                        title={'Greeting'}
                        title_jp={'院長あいさつ'}
                    />
                    <Box padding={['10px']}>
                        <GreetingHome />
                    </Box>

                </Box>
                <StyledBox>
                    <BackgroundWrapper color={'white'} />

                    <HeadingLogo
                        title={'Symptoms'}
                        title_jp={'症状別治療法'}
                    />


                    <NewsList postNum={3} categoryId={154} />
                </StyledBox>
                <StyledBox>
                    <BackgroundWrapper color={'#f5f0e9'} />

                    <HeadingLogo
                        title={'TreatmentMenu'}
                        title_jp={'施術内容'}
                    />
                    <Box marginBottom={['20px']}>
                        <StyledText>
                            <Text lineHeight={["40px"]}> 一人ひとりにあった</Text>
                            <Text lineHeight={["40px"]}>最適な治療方法を提案します</Text>
                        </StyledText>
                    </Box>

                    <TreatmentsLists />
                </StyledBox>
                <StyledBox>
                    <BackgroundWrapper color={'white'} />
                    <HeadingLogo
                        title={'ServiceFlow'}
                        title_jp={'施術の流れ'}
                    />
                    <ServiceFlowOverview />
                </StyledBox>
                <StyledBox>
                    <HeadingLogo
                        title={'News'}
                        title_jp={'お知らせ'}
                    />
                    <NewsList postNum={3} />
                </StyledBox>
            </VStack>





        </>
    );
}
