import { Outlet, useParams } from "react-router-dom";
import FirstView from "../../components/firstView";
import { NewsList } from "../../components/news/NewsList";
import { LatestNews } from "../../components/news/LatestNews";



export default function NewsPage() {
    const { newsId } = useParams();
    // console.log(newsId)
    return (
        <>
            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'News'} />
            {newsId === undefined ?
                <LatestNews /> : ""}
            <Outlet />
            <NewsList postNum={6} />

        </>
    );
}
