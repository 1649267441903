import { Img } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// カルーセルにする画像のソースをリストにします
const slidePath = `${process.env.PUBLIC_URL}/images/slide/`;
const defaultItems = [
    { id: 0, url: `${slidePath}0.jpg` },
    { id: 1, url: `${slidePath}1.jpg` },
    { id: 2, url: `${slidePath}2.jpg` },
    { id: 3, url: `${slidePath}3.jpg` },
    // { id: 4, url: `${slidePath}4.jpg` },
    { id: 5, url: `${slidePath}5.jpg` },
    { id: 6, url: `${slidePath}6.jpg` },
    { id: 7, url: `${slidePath}7.jpg` },
]
export default function Slider({ items = defaultItems }) {
    // const publicURL = process.env.PULIC_URL

    return (
        <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1} //一度に表示するスライドの数
            pagination={{
                clickable: true,
            }} //何枚目のスライドかを示すアイコン、スライドの下の方にある
            navigation //スライドを前後させるためのボタン、スライドの左右にある

            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
        >
            {items.map((item) => {
                return (
                    <SwiperSlide key={`${item.id}`} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Img src={item.url} alt={item.id} />

                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

