import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { IconContext } from 'react-icons';
import { FaAngleDown } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';


export default function NavMenu({ item }) {
    const { pathname } = useLocation();
    return (
        <>
            <Menu>
                <MenuButton fontWeight='bold' _hover={{ opacity: '0.3' }} >
                    {item.name}
                    <IconContext.Provider value={{ color: 'black', size: '1em' }}>
                        <FaAngleDown style={{ display: 'inline-block', verticalAlign: 'middle' }} />
                    </IconContext.Provider>
                </MenuButton>
                <MenuList>
                    {item.dropdownMenu.map((menu) => {
                        const bgcolor = pathname === `/${item.link}#${menu.link}` ? '#E2E8F0;' : '#fff';
                        return (
                            <MenuItem
                                key={menu.name}
                                backgroundColor={bgcolor}
                                _hover={{ backgroundColor: '#E2E8F0' }}
                            >
                                {/* <ALink href={`/test/${item.link}#${menu.link}`}>
                                    {menu.name}
                                </ALink> */}
                                <Link to={`/${item.link}/${menu.link}`}>
                                    {menu.name}
                                </Link>
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        </>

    )

}