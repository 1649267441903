import { Button, HStack, StackDivider, VStack, } from "@chakra-ui/react"
import { ServiceFlowInfo } from "../../data/Infomations"
import { ServiceFlowCard } from "./ServiceFlowCard"
import { FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
export const ServiceFlowOverview = ({ isLink = false }) => {
    return (
        <VStack
            className="box-shadow"
            divider={<StackDivider borderColor='gray' />}
            alignItems={['start']}
            w={['260px', '260px', '300px']}
            margin={['0 auto']}
            padding={['30px 20px 20px']}
            background={['white']}
        >
            {isLink ? ServiceFlowInfo.map((service, id) => {
                return (
                    <a href={`#${service.id}`} key={id}>
                        <ServiceFlowCard service={service} id={id} key={id} />
                    </a>
                )
            }) :
                ServiceFlowInfo.map((service, id) => {
                    return (
                        <ServiceFlowCard service={service} id={id} key={id} />
                    )
                })}


            {isLink ? '' :
                <HStack
                    w={['100%']}
                    justifyContent={['center']}
                    marginTop={['20px']}
                >
                    <Link to='/serviceflow'>
                        <Button
                            leftIcon={<FaCaretRight />}
                            colorScheme="teal"
                            variant='outline'
                            fontSize={['sm']}
                        >
                            詳しくはこちら
                        </Button>
                    </Link>
                </HStack>
            }


        </VStack>
    )
}