import { VStack, Text, HStack, Link, Icon, Box } from "@chakra-ui/react";
import LogoNormal from "../homeLinkLogo/normal";
import TellLink from "../infomation/tel";
import { AddressInfo } from "../../data/Infomations";
import { LINEInfo, InstagramInfo, TellPhoneNums } from '../../data/Infomations';
import { FaLine, FaInstagram, FaPhone } from "react-icons/fa";


export default function DrawerFooterContents() {
    return (
        <>
            <VStack
                width='100%'
                alignItems='center'
                margin='0 0 50px'

                whiteSpace="unset"

            >
                <HStack>
                    <Link href={LINEInfo.friendUrl} isExternal>
                        <Icon
                            as={FaLine}
                            color={'#06C755'}
                            w={['40px']}
                            h={['40px']}
                            verticalAlign={'middle'}
                        />
                    </Link>
                    <Link href={InstagramInfo.url} isExternal>
                        <Box
                            w={['38px']}
                            h={['38px']}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={['8px', '7px', '7px', '7px', '8px']}
                            verticalAlign={'middle'}
                            background={'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)'}
                        >
                            <Icon
                                as={FaInstagram}
                                color={'white'}
                                w={['35px']}
                                h={['35px']}
                                verticalAlign={'middle'}
                            />
                        </Box>
                    </Link>

                    <Link href={TellPhoneNums.link} isExternal>
                        <Icon
                            as={FaPhone}
                            w={['25px']}
                            h={['25px']}
                            verticalAlign={'middle'}
                        />
                    </Link>


                </HStack>

                <Text
                    maxWidth='100%'
                    overflowWrap="break-word"
                >
                    〒{AddressInfo.num}
                </Text>
                <Text>
                    {AddressInfo.jpn}
                </Text>
                <TellLink />
                <LogoNormal />
            </VStack >
        </>
    )

}