import { Box } from "@chakra-ui/react";

export const BackgroundWrapper = ({ color = 'white' }) => {
    return (
        <Box
            position='absolute'
            top='0'
            left='0'
            width='100vw'
            height='100%'
            background={color}
            zIndex='-1'
            margin={['0 calc(50% - 50vw)']}
        />
    )
}