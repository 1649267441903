import Header from "../header";
import Footer from "../footer/footer";
import { Outlet } from "react-router-dom";


export default function LayoutPage() {
    return (
        <>
            <Header position="top" />
            <main className="layoutPage">
                <Outlet />
            </main>
            <Footer />
        </>
    );
}
