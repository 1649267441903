import { Link} from 'react-router-dom';
import { Img } from '@chakra-ui/react';



export default function HomeLinkLogo({logoPath}) {
    

    return (
        <>
            <Link to='/'>
                    <Img src={`${process.env.PUBLIC_URL}/images/logo/${logoPath}.png`} alt={`hikaru-acu_logo_${logoPath}`} />
                    
            </Link>
        </>
    )
}