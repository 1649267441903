import NavList from './navList';
import { MenuItems } from '../../data/Infomations';
import NavListDropDown from './navListDropDown';



export default function NavigationMenu({ color, isDropDown = false }) {
    return (
        <>
            {isDropDown ?
                <NavListDropDown color={color} items={MenuItems} /> :
                <NavList color={color} items={MenuItems} />}
        </>

    )

}