import { useEffect, useState } from "react";


export function IsScroll({ scrollY }) {

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        window.scrollY > scrollY
            ? setIsVisible(true)
            : setIsVisible(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility)
        return () => {
            window.removeEventListener("scroll", toggleVisibility)
        }
    }, [])

    return isVisible
}