import Header from "../header";
import Footer from "../footer/footer";
import { Outlet } from "react-router-dom";

export default function LayoutHome() {
    return (
        <>
            <Header position="top" />
            <main className="layoutHome">
                <Outlet/>
            </main>
            <Footer />
        </>
    );
}
