import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import AboutPage from './pages/about'
import AccessPage from './pages/access'
import TreatmentsPage from './pages/treatments'
import SingleTreatment from './pages/treatments/treatment';

import ReviewsPage from './pages/reviews'
import PricePage from './pages/price';
import ServiceFlowPage from './pages/serviceflow';
import NoMatch from './components/noMatchRoute/NoMatchRoute';
import LayoutHome from './components/layout/layoutHome';
import LayoutPage from './components/layout/layoutPage';
import ScrollTop from './components/scrollTop';
import NewsPage from './pages/news';
import SingleNews from './pages/news/singleNews';


function App() {
  return (
    <>
      <ScrollTop />



      <Routes>
        <Route element={<LayoutHome />}>
          <Route exact path="/" element={<HomePage />} />
        </Route>

        <Route element={<LayoutPage />}>
          <Route path="access" element={<AccessPage />} />

          <Route path="treatments" element={<TreatmentsPage />}>
            <Route path=':treatmentName' element={<SingleTreatment />} />
          </Route>

          <Route path="news" element={<NewsPage />}>
            <Route path=':newsId' element={<SingleNews />} />
          </Route>

          <Route path="serviceflow" element={<ServiceFlowPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="reviews" element={<ReviewsPage />} />
          <Route path="price" element={<PricePage />} />
          <Route path="*" element={<NoMatch />} />
        </Route>


      </Routes>
    </>
  );
}

export default App;
