import { Outlet } from "react-router-dom";
import FirstView from "../../components/firstView";
import { Box, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { HeadingLogo } from "../../components/heading/HeadingLogo";
import { TreatmentsLists } from "../../components/treatmenstList/TreatmentsLists";
import { NewsList } from "../../components/news/NewsList";


export default function TreatmentsPage() {
    const StyledUnorderedList = ({ children, ...rest }) => {
        return (
            <UnorderedList
                paddingLeft={['10px', '10px']}
                {...rest}

            >
                {children}
            </UnorderedList>
        )
    }
    const StyledListItem = ({ children, ...rest }) => {
        return (
            <ListItem
                {...rest}
            >
                {children}
            </ListItem>)
    }
    const StyledBox = ({ children, ...rest }) => {
        return (
            <Box
                padding={['10px']}
                marginTop={['-80px']}
                paddingTop={['80px']}
                {...rest}
            >
                {children}
            </Box>
        )
    }
    const HeaderImage = ({ imagePath, ...rest }) => {
        const imgURL = `${process.env.PUBLIC_URL}/images/treatments/${imagePath}`
        return (
            <Box

                backgroundSize={'contain'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundImage={`url("${imgURL}")`}
                height={['200px', '300px', '300px']}
                margin={['5px 10px', '5px 10px', '25px 10px',]}
                {...rest}

            />
        )
    }
    return (
        <>
            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'Treatments'} />
            <Outlet />
            <Box
                className="box-shadow mainSection"
                margin={['40px 0px', '40px 0px', '40px 0']}
                backgroundColor='white'
                padding={['10px', '20px', '30px']}            >
                <Box>
                    <HeadingLogo
                        title={'TreatmentMenu'}
                        title_jp={'施術内容'}
                    />
                    <TreatmentsLists isA={true} />
                </Box>
                <Box>
                    <HeadingLogo
                        title={'Symptoms'}
                        title_jp={'症状別治療法'}
                    />
                    <NewsList postNum={3} categoryId={154} />
                </Box>

                <Box>
                    <StyledBox
                        className="kinesio content"
                        id="kinesio"
                    >
                        <HeadingLogo
                            title={'キネシオテーピング療法'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <Box
                            className='content_innerBox'
                        >
                            <HeaderImage imagePath={'kinesio.jpg'} />
                            <Text>
                                キネシオテーピングは現在90カ国以上で普及している治療法です。スポーツ障害や腰痛、肩こり、膝の痛み等の慢性疾患、自律神経症状等様々な場面で使用されています。テーピングは固定のイメージをされている方も多いのではないでしょうか？キネシオテーピングは関節の動きを妨げずに効果を発揮することができます。キネシオテーピングを正しく貼付することで以下のような効果を期待できます。
                            </Text>
                            <StyledUnorderedList paddingTop={['10px']}>
                                <StyledListItem>筋肉のサポート</StyledListItem>
                                <Text>弱って力の入らない筋肉のサポート、張りすぎている筋肉の緊張を和らげる等</Text>
                                <StyledListItem>身体の循環を改善</StyledListItem>
                                <Text>テープを貼付することで血液、リンパ液の循環を改善し、回復を促進させます。</Text>
                                <StyledListItem>痛みの軽減</StyledListItem>
                                <Text>発痛物質の除去、皮膚の感覚受容器を刺激することで痛みを軽減させます</Text>
                                <StyledListItem>関節を支える</StyledListItem>
                                <Text>筋肉に力を入れやすくすることで、関節を支え安定させることができます。</Text>
                            </StyledUnorderedList>
                            <Heading as={'h3'} fontSize={['1.2em']} paddingTop={['10px']}>対応疾患</Heading>
                            <StyledUnorderedList paddingTop={['10px']}>
                                <StyledListItem>外傷(骨折、脱臼、捻挫、打撲等の早期回復)</StyledListItem>
                                <StyledListItem>慢性疾患(腰痛、肩こり、膝の痛みの軽減と可動域回復)</StyledListItem>
                                <StyledListItem>自律神経症状(頭痛、神経痛、不眠等の改善)</StyledListItem>
                            </StyledUnorderedList>


                        </Box>

                    </StyledBox>
                    <StyledBox
                        className="muscle_slugging content"
                        id="muscle_slugging"
                    >
                        <HeadingLogo
                            title={'筋スラッキング療法'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <Box
                            className='content_innerBox'
                        >
                            <HeaderImage imagePath={'muscle_slugging.jpg'} />

                            <Text>
                                筋肉の本来の機能は縮むことです。筋スラッキング療法は手技、機材を使い筋肉本来の機能を取り戻す施術です。
                            </Text>
                        </Box>

                    </StyledBox>
                    <StyledBox
                        className="cryotherapy content"
                        id="cryotherapy"
                    >
                        <HeadingLogo
                            title={'クライオセラピー＆アイスマッサージ'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <Box
                            className='content_innerBox'
                        >
                            <HeaderImage imagePath={'cryotherapy.jpg'} />

                            <Text>
                                痛みの原因は炎症です。急性、慢性問わずそれに変わりはありません。クライオセラピーは患部を冷却することで炎症を取り除き痛みを除去します。アイスマッサージは患部を冷却しながらマッサージすることでマッサージ後の揉み返し、ダルさを軽減し術後の違和感を軽減してくれます。
                            </Text>
                        </Box>
                    </StyledBox>
                    <StyledBox
                        className="myofasical_release content"
                        id="myofasical_release"
                    >
                        <HeadingLogo
                            title={'筋膜リリース'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <Box
                            className='content_innerBox'
                        >
                            <HeaderImage imagePath={'myofasical_release.jpg'} />
                            <Text>
                                メディセルという機材を使用し、皮膚を吸引して筋膜の癒着を剥がして血液、リンパ液の循環を改善してコリ、ムクミ、内出血などの症状を軽減します。
                            </Text>
                        </Box>
                    </StyledBox>
                    <StyledBox
                        className="exercise_thrapy content"
                        id="exercise_therapy"
                    >
                        <HeadingLogo
                            title={'運動療法'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <Box
                            className='content_innerBox'
                        >
                            <HeaderImage imagePath={'exercise_therapy.jpg'} />
                            <Text>
                                キネシオテーピング療法とはキネシオロジー(筋肉運動学)を基にして作られている治療法です。そのため正しく筋肉を動かす療法としてマッスルユニットトレーニング、体幹トレーニング等の運動療法でケガの再発防止、パフォーマンスの向上をしていきます
                            </Text>
                        </Box>
                    </StyledBox>
                    <StyledBox
                        className="physical_therapy content"
                        id="physical_therapy"
                    >
                        <HeadingLogo
                            title={'物理療法'}
                            fontSize={['1.5rem', '2rem']}
                        />
                        <HeaderImage imagePath={'physical_therapy.jpg'} />
                        <Box
                            className='content_innerBox'
                        >
                            <Text>

                            </Text>
                        </Box>
                    </StyledBox>
                </Box>

            </Box>


        </>
    );
}
