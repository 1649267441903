import { Box, Heading, ListItem, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, UnorderedList } from "@chakra-ui/react";
import FirstView from "../../components/firstView";
import { HeadingLogo } from "../../components/heading/HeadingLogo";



export default function PricePage() {
    const StyledUnorderedList = ({ children, ...rest }) => {
        return (
            <UnorderedList
                paddingLeft={['10px', '10px']}
                {...rest}

            >
                {children}
            </UnorderedList>
        )
    }
    const StyledListItem = ({ children, ...rest }) => {
        return (
            <ListItem
                {...rest}
            >
                {children}
            </ListItem>)
    }
    const StyledBox = ({ children, ...rest }) => {
        return (
            <Box
                padding={['10px']}
                {...rest}
            >
                {children}
            </Box>
        )
    }
    return (
        <>

            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'PRICE'} />

            <Box
                className="box-shadow mainSection"
                margin={['40px 0px', '40px 0px', '40px 0']}
                backgroundColor='white'
                padding={['10px', '20px', '30px']}
            >
                <Box>
                    <HeadingLogo title={'保険治療'} fontSize={['1.5rem', '2rem']} />
                    <StyledBox>
                        <Text>
                            接骨院の治療では保険の適用範囲が法律で定められており、保険が適用される治療と適用されない治療がございます。ご了承くださいませ。
                        </Text>
                        <Box margin={['20px 0']}>
                            <Heading as={'h3'} fontSize={['1.2em']}>健康保険が適用される治療</Heading>
                            <StyledUnorderedList paddingTop={['10px']}>
                                <StyledListItem>骨折、脱臼</StyledListItem>
                                <StyledListItem>打撲</StyledListItem>
                                <StyledListItem>捻挫</StyledListItem>
                                <StyledListItem>挫傷(筋挫傷、筋挫滅、肉離れ等)</StyledListItem>
                                <Text>
                                    急性、亜急性外傷のけがにつきましては健康保険のご利用が可能です。骨折、脱臼は、近隣の医師より同意をいただいております。しかし、同様の症状で あっても受傷原因や、受傷日時の分からないものに関 しては適用外となります。
                                </Text>
                            </StyledUnorderedList>
                        </Box>
                        <Box margin={['20px 0']}>
                            <Heading as={'h3'} fontSize={['1.2em']}>健康保険が適用されない治療</Heading>

                            <StyledUnorderedList paddingTop={['10px']}>
                                <StyledListItem>慢性疾患</StyledListItem>
                                <Text>
                                    （慢性疾患とは、原因のはっきりしない生活習慣などによる、こりやだるさのことです。下記のような慢性的疾患には健康保険は適用されません。）
                                </Text>
                                <StyledListItem>肩こり</StyledListItem>
                                <StyledListItem>足のだるさ</StyledListItem>
                                <StyledListItem>慢性腰痛</StyledListItem>
                                <StyledListItem>全身的倦怠感</StyledListItem>
                                <StyledListItem>目の疲れ</StyledListItem>
                                <StyledListItem>更年期障害</StyledListItem>
                                <StyledListItem>頭痛</StyledListItem>
                                <StyledListItem>自律神経失調症</StyledListItem>
                                <StyledListItem>医療機関で保険適用治療を受けている場合</StyledListItem>
                                <Text>
                                    （同一外傷ですでに医療機関の治療を受けている場合、接骨院での治療は保険適用外となります。）
                                </Text>
                            </StyledUnorderedList>
                        </Box>


                    </StyledBox>

                </Box>
                <Box>
                    <HeadingLogo title={'健康保険適用の料金目安表'} fontSize={['1.2rem', '2rem']} />
                    <StyledBox>
                        <TableContainer>
                            <Table variant='sinple'>
                                <TableCaption>負担割合3割の場合</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>{""}</Th>
                                        <Th>1部位</Th>
                                        <Th>2部位</Th>
                                        <Th>3部位</Th>
                                    </Tr>

                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>初診</Td>
                                        <Td>¥720</Td>
                                        <Td>¥980</Td>
                                        <Td>¥1,220</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>2回目より</Td>
                                        <Td>¥310</Td>
                                        <Td>¥490</Td>
                                        <Td>¥600</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Text>
                            接骨院(整骨院)では、施術料を1回いくらという料金ではなく、1か所(部位)いくらと計算します。そのため、痛みが身体の1か所だとしても、痛みの原因が複数に有ることもあります。その痛みを治すために、その複数ある問題点に施術する事で治療を進めて行きます。したがって治療代が、複数部位の料金になることもございます。
                        </Text>
                        <Text>
                            例えば、腰痛の場合ですと痛みがあるのは腰だけでも、問題は下肢にあったりします。下肢の筋が緊張する事で、骨盤が歪んで来ます。この歪みが腰痛を引き起こします。つまり、この腰痛の原因は下肢の緊張により引き起こされています。これを筋膜連鎖と言います。我々は、腰の痛みのみならず、その原因である下肢より治していきます。
                        </Text>
                    </StyledBox>

                </Box>

                <Box>
                    <HeadingLogo title={'自費治療料金'} fontSize={['1.5rem', '2rem']} />
                    <StyledBox>
                        <Text>
                            症状により異なり2000円～4000円が目安になります。※治療法は相談の上、患者さんに最適な治療を施していきます。
                        </Text>
                        <StyledUnorderedList paddingTop={['10px']}>
                            {/* <StyledListItem>酸素カプセル</StyledListItem>
                            <Text>1回（40分）　2,500円（税抜き）</Text> */}
                            <StyledListItem>交通事故</StyledListItem>
                            <Text>窓口でのお支払いは不要です。自賠責保険などの自動車保険が適用されます。※過失割合により変わる場合もあります。</Text>
                            <StyledListItem>労災</StyledListItem>
                            <Text>窓口でのお支払いは不要です。内容により一部自費となる場合もあります。</Text>
                        </StyledUnorderedList>
                    </StyledBox>


                </Box>
            </Box>

        </>
    );
}
