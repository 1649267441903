import { Heading, Text, Box } from '@chakra-ui/react'


export const HeadingLogo = ({
    title,
    title_jp,
    color = 'logoColor.g',
    margin = '100px'
    , ...rest
}) => {
    const beforeImageURL = `${process.env.PUBLIC_URL}/images/heading_before2.png`
    return (

        <Box
            className='headingLogo'
            marginTop={['20px']}
            marginBottom={['20px']}
            w={['100%']}
            alignItems='start'
            _before={{
                content: '""',
                backgroundImage: `url(${beforeImageURL})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                display: 'inline-block',
                w: { base: '45px', sm: '50px', md: '58px' },
                h: { base: '40px', sm: '45px', md: '50px' },
                overflow: 'hidden',
                verticalAlign: 'text-bottom'
            }}>
            <Heading
                display='inline-block'
                color={color}
                fontFamily='serif'
                {...rest}
            >
                {title}
            </Heading>


            <Box
                className='headingLogo_line'
                w={['100%']}
                position='relative'
                fontFamily='"Zen Maru Gothic"'
                _after={{
                    position: 'absolute',
                    content: '""',
                    display: 'inline-block',
                    height: '1px',
                    background: `${color}`,
                    left: '0',
                    right: '0',
                    marginLeft: `${title_jp === undefined ? 0 : margin}`,
                    top: '9px'
                }}
            >
                <Text>{title_jp}</Text>
            </Box>

        </Box>

    )
} 