import { extendTheme } from "@chakra-ui/react";

const customThme = {
    colors: {
        logoColor: {
            g: "#8fc31f",
            y: "#f8b73d"
        }

    }
}

export const theme = extendTheme(customThme);