import { Box } from "@chakra-ui/react";
import Slider from "./slider";


export default function FullSlideShow({ items }) {
    return (
        <Box
            margin={["0 calc(50% - 50vw)"]}
            w={['100vw']}
            maxHeight={['800px']}
            overflow={['hidden']}
            display={['flex']}
            alignItems={['center']}
        >
            <Slider items={items} />
        </Box>
    )
}
