import { Link} from 'react-router-dom';
import { Img } from '@chakra-ui/react';


export default function LogoNormal() {
    const logos = {
        pc: {
            url: '/logo/normal.png',
            alt: 'hikaru-acu_logo'
        },
        sp: {
            url: '/logo/sp_nomal.png',
            alt: 'hikaru-acu_logo_sp'
        }
    }

    return (
        <>
            <Link to='/'>
                    <Img src={`${process.env.PUBLIC_URL}/images/logo/normal.png`} alt={logos.pc.alt} />
                    
            </Link>
        </>
    )
}