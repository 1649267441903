import { Box, Text } from "@chakra-ui/react"
import { GreetingContents } from "../../data/Infomations"


export const GreetingHome = () => {
    return (
        <Box
            fontFamily='"Zen Maru Gothic"'
            fontSize={["sm", "md", "md"]}
            fontWeight={["medium"]}
            gap={['0.5em']}
            display={['flex']}
            flexWrap={['wrap']}
        >
            <Box
                width={['100%']}
                backgroundSize={'contain'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundImage={`url("${process.env.PUBLIC_URL}/images/director.jpg")`}
                height={['200px', '200px', '200px']}
                margin={['5px 10px', '5px 10px', '25px 10px',]}
                alt={'院長写真'}
            />
            {GreetingContents.contents.map((content, id) => {
                return (
                    <Text key={id}>{content}</Text>
                )
            })}
            <Text w={['100%']} textAlign={['end']}>{GreetingContents.title}</Text>
            <Text w={['100%']} textAlign={['end']}>{GreetingContents.name}</Text>
        </Box>

    )
}