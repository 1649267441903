import FirstView from "../..//components/firstView";


export default function ReviewsPage() {
    return (
        <>

            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'Review'} />




        </>
    );
}
