import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    VStack,
    StackDivider, HStack
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function AccordionMenu({ item, onClose }) {
    const ClickHandler = () => {
        onClose();
    }
    return (
        <Accordion allowToggle width='100%'>
            <AccordionItem border='none' width='100%'>

                <AccordionButton padding='0' fontWeight='bold' _hover={{ background: '#fff' }}>

                    {item.name}
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel paddingLeft='0px' width='100%'>
                    <VStack
                        divider={<StackDivider />}
                        width="100%"
                        bgColor="white"
                        borderColor="blackAlpha.100"
                        borderWidth="1px"
                        borderRadius="2px"
                        p={5}
                        alignItems="start"
                        fontWeight='medium'
                        fontSize='sm'
                        padding='5px'
                    >
                        {item.dropdownMenu.map((menu) => {
                            return (
                                <HStack key={menu.name} width='100%'>
                                    <Link
                                        to={`${item.link}/${menu.link}`}
                                        style={{ width: '100%', height: '100%' }}
                                        onClick={ClickHandler}
                                    >
                                        {menu.rname ? menu.rname.map((name)=><p>{name}</p>): menu.name}
                                    </Link>
                                </HStack>

                            )
                        })}
                    </VStack>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )

}