import { Box, HStack, Hide, Icon, Link, Text } from '@chakra-ui/react';
import { LINEInfo, InstagramInfo, TellPhoneNums } from '../../data/Infomations';
import { FaLine, FaInstagram, FaPhone } from "react-icons/fa";



export default function SNSLinks() {

    return (
        <>
            <Box
                display={'flex'}
                gap={['0.5em']}
            >
                <HStack
                    justifyContent={['space-around']}
                >
                    <Link
                        href={LINEInfo.friendUrl}
                        isExternal
                        transition='all 0.3s'
                        _hover={{ opacity: 0.3 }}
                    >
                        <Icon
                            as={FaLine}
                            color={'#06C755'}
                            w={['32px', '32px', '35px', '35px', '40px']}
                            h={['32px', '32px', '35px', '35px', '40px']}
                            verticalAlign={'middle'}
                        />
                    </Link>
                    <Link
                        href={InstagramInfo.url}
                        isExternal
                        transition='all 0.3s'
                        _hover={{ opacity: 0.3 }}
                    >
                        <Box
                            w={['30px', '30px', '34px', '34px', '38px']}
                            h={['30px', '30px', '34px', '34px', '38px']}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={['8px', '7px', '7px', '7px', '8px']}
                            verticalAlign={'middle'}
                            background={'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)'}
                        >
                            <Icon
                                as={FaInstagram}
                                color={'white'}
                                w={['28px', '28px', '31px', '31px', '35px']}
                                h={['28px', '28px', '31px', '31px', '35px']}
                                verticalAlign={'middle'}
                            />
                        </Box>


                    </Link>
                </HStack>
                <HStack
                    gap={['0.3em']}
                >
                    <Link
                        href={TellPhoneNums.link}
                        isExternal
                        transition='all 0.3s'
                        _hover={{ opacity: 0.3 }}
                    >
                        <Icon
                            as={FaPhone}
                            w={['20px', '22px', '20x', '22x', '25px']}
                            h={['20px', '22px', '20x', '22x', '25px']}
                            verticalAlign={'middle'}
                        />
                        <Hide below='sm'>
                            <Text
                                display={'inline-block'}
                                fontSize={['14px', '16px', '18px', '20px', '22px']}
                                fontWeight={['bold']}
                                verticalAlign={'middle'}

                            >
                                {TellPhoneNums.num}
                            </Text>
                        </Hide>

                    </Link>
                </HStack>

            </Box>
        </>
    )
}