import NavigationMenu from "../nav/nav";
import HomeLinkLogo from "../homeLinkLogo";
import { VStack, HStack, Text, IconButton, Link, Img, } from "@chakra-ui/react";
import TellLink from "../infomation/tel";
import { FaLine, FaInstagram } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { TellPhoneNums, LINEInfo, AddressInfo, InstagramInfo } from "../../data/Infomations";
import ReservationBanner from "../banner/ReservationBanner";



export default function Footer() {
    return (
        <>
            <VStack
                width="100%"
                height='auto'
                paddingTop='40px'
                bgColor="blackAlpha.900"
                borderColor="blackAlpha.100"
                borderWidth="1px"
                borderRadius="3px"
                p={5}
                alignItems="center"
                fontWeight='bold'
                color='white'
                whiteSpace="unset"
            >
                <VStack marginBottom='20px'>
                    <HomeLinkLogo logoPath={'normal'} />
                </VStack>

                <VStack
                    spacing={3}
                    marginBottom='20px'
                >
                    <Text>〒{AddressInfo.num} {AddressInfo.jpn}</Text>
                    <TellLink isTell={true} />
                    <Img src={`${process.env.PUBLIC_URL}/images/banner/schedule.png`} alt="営業時間" />
                </VStack>
                <VStack
                    width='100%'
                    boxSizing="border-box"
                    marginBottom='20px'
                >
                    <NavigationMenu color={'white'} />
                </VStack>
                <ReservationBanner />
                <VStack>

                    <HStack
                        width='100%'
                        justifyContent='center'
                        spacing='20px'
                    >
                        <Link href={LINEInfo.friendUrl} rel="noopener noreferrer" >
                            <IconButton
                                aria-label="LINE"
                                icon={<FaLine />}
                                fontSize='32px'
                                transition='all 0.3s'
                                _hover={{ opacity: 0.3 }} />
                        </Link>
                        <Link href={InstagramInfo.url} rel="noopener noreferrer" >
                            <IconButton
                                aria-label="Instagram"
                                icon={<FaInstagram />}
                                fontSize='32px'
                                transition='all 0.3s'
                                _hover={{ opacity: 0.3 }} />
                        </Link>
                        <Link href={`tel:${TellPhoneNums.num}`}>
                            <IconButton
                                aria-label="Call"
                                icon={<BsTelephoneFill />}
                                fontSize='25px'
                                transition='all 0.3s'
                                _hover={{ opacity: 0.3 }} />
                        </Link>

                    </HStack>
                </VStack>

            </VStack>
        </>
    )
}