import { useEffect, useState } from "react"
import { getPostImagePath } from "../../api/WorpressPosts/wordpress";
import { Box, Heading, Icon, Img, Text, VStack } from "@chakra-ui/react";
import { CiClock2 } from "react-icons/ci";
import { Link } from "react-router-dom";



export const SingleNewsCard = ({ post }) => {
    const [postImageURL, setPostImageURL] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const postDate = post.date.slice(0, 10).split('-').join('.');
    const categories = post.categoryNames ? post.categoryNames.join(',') : "";
    const tags = post.tagNames ? '#' + post.tagNames.join('#') : "";

    useEffect(() => {
        const isImage = post._links["wp:featuredmedia"] !== undefined ? true : false;
        const postImageApiURL = isImage ? post._links["wp:featuredmedia"][0].href : process.env.REACT_APP_WORDPRESS_DEFAULT_IMAGE_URL;

        // console.log(post._links["wp:featuredmedia"], isImage, postImageApiURL)

        const fetchPostImage = async (url) => {
            let res = await getPostImagePath(url);
            // console.log(res.source_url);
            setPostImageURL(await res);
            setIsLoading(false)
        }
        if (isImage) {

            fetchPostImage(postImageApiURL);
        } else {
            setPostImageURL(postImageApiURL);
            setIsLoading(false);
        }

    }, [post])



    // console.log(postDate)

    return (
        <>

            <VStack
                width={['calc((100% - 0.5em) / 2)', 'calc((100% - 0.5em) / 2)', 'calc((100% - 1em) / 3)']}
                gap='0'
                height={['250px', '250px', '280px']}
                padding='0.5em'
                overflow='hidden'
                boxShadow='2px 2px 9px 2px #777777'
                borderRadius='7px'
                transition='all 0.4s'
                cursor='pointer'
                backgroundColor='white'
                _hover={{ boxShadow: '5px 5px 20px -10px #777777', transform: 'translateY(5px)' }}

            >
                <Link to={`/news/${post.id}`}>

                    <Box
                        fontSize='14px'
                        w='100%'
                        display='flex'
                        justifyContent='space-between'
                    >
                        <Text><Icon as={CiClock2} verticalAlign='-2.2px' />{postDate}</Text>
                        <Text fontSize='13px'>{categories}</Text>
                    </Box>
                    <Text fontSize='13px' w='100%' textAlign='right'>{tags}</Text>
                    <VStack
                        className="newsImage"
                        // padding={['auto', 'auto', '0 30px']}
                        maxHeight='110px'
                        aspectRatio='16/9'
                        overflow='hidden'
                        margin='0 auto 0.5em'

                    >
                        {isLoading ? <Text>Now Loading...</Text> : <Img src={postImageURL} />}
                    </VStack>
                    <VStack className="newsContent" width='100%'>
                        <Heading
                            as='h3'
                            size='sm'
                            noOfLines={1}
                            maxWidth='100%'
                            padding='0 4px'
                            wordBreak='break-word'
                        >{post.title.rendered}</Heading>
                        <Text
                            fontSize='sm'
                            className="blog-excerpt"
                            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                            noOfLines={4}
                        />
                    </VStack>
                </Link>

            </VStack>

        </>
    )
}