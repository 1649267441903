import { useEffect, useState } from "react";
import { getWordpressPosts } from "../../api/WorpressPosts/wordpress";
import { FaClockRotateLeft } from "react-icons/fa6";
import { CiClock2 } from "react-icons/ci";
import { HStack, Heading, VStack, Text, Icon, Box } from "@chakra-ui/react";
import { NewsHeaderImage } from "./NewsHeaderImage";
import { SetOptionNamesToPost } from "../../hooks/post/SetOptionNames";
import "../../pages/news/singleNews/singleNews.css"

const categoriesURL = process.env.REACT_APP_WORDPRESS_CATEGORIES_URL;
const tagsURL = process.env.REACT_APP_WORDPRESS_TAGS_URL;
export const LatestNews = () => {
    const [isPost, setIsPost] = useState(true);
    const [post, setPost] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [dates, setDates] = useState({ postDate: '', lastEditDate: "" });
    const [options, setOptions] = useState({ category: '', tag: '' });

    const initialURL = process.env.REACT_APP_WORDPRESS_API_URL;
    const url = `${initialURL}?per_page=1`

    useEffect(() => {
        const fetchPostData = async (url) => {
            const data = await getWordpressPosts(url);
            // console.log(await data)
            if (!data[0]) {
                setIsPost(false);
            } else {
                const categoriesData = await getWordpressPosts(categoriesURL);
                const tagsData = await getWordpressPosts(tagsURL);
                const newPost = await SetOptionNamesToPost(data[0], categoriesData, tagsData)

                setPost(newPost)
                setIsLoading(false)
                const postDate = data[0].date.slice(0, 10).split('-').join('.');
                const lastEditDate = data[0].modified.slice(0, 10).split('-').join('.');
                setDates({ postDate: postDate, lastEditDate: lastEditDate });

                const category = newPost.categoryNames ? newPost.categoryNames.join(',') : "";
                const tag = newPost.tagNames ? '#' + newPost.tagNames.join('#') : "";
                setOptions({ category: category, tag: tag });
            }

        }
        fetchPostData(url);
    }, [])
    // console.log(isPost)
    return (
        <>
            {isLoading ? <h2>News を準備しています。</h2> :
                <>{isPost ?
                    <>
                        <VStack
                            className="singleNews background-white"
                            width='100%'
                            padding={['5px', '5px', '10px']}
                            display='flex'
                            justifyContent='center'
                            marginBottom={['30px']}

                        >

                            <Heading
                                size='md'
                                marginBottom={['10px']}
                                wordBreak='break-word'>
                                {post.title.rendered}
                            </Heading>
                            <VStack>
                                <NewsHeaderImage post={post} />
                            </VStack>
                            <HStack
                                width='100%'
                                fontSize='sm'
                                alignItems='end'
                                justifyContent='end'
                            >
                                <Text>
                                    <Icon
                                        as={FaClockRotateLeft}
                                        verticalAlign='-2.2px'
                                        marginRight='5px' />
                                    {dates.lastEditDate}
                                </Text>
                                <Text>
                                    <Icon
                                        as={CiClock2}
                                        verticalAlign='-2.2px'
                                        marginRight='5px' />
                                    {dates.postDate}
                                </Text>
                            </HStack>
                            <Box w='100%' fontSize='sm' textAlign='right'>
                                <Text>{options.category}</Text>
                                <Text>{options.tag}</Text>
                            </Box>
                            <VStack>

                            </VStack>
                            <div
                                className="article"
                                dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                            />
                        </VStack>

                    </> :
                    <h2>ニュースはまだありません</h2>
                }</>

            }


        </>
    )
}