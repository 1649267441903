import { VStack, StackDivider, HStack } from "@chakra-ui/react";
import AccordionMenu from "../dropdown/AccordionMenu";

import { Link } from "react-router-dom";

export default function DrawerList({ items, onClose }) {
    const ClickHandler = () => {
        onClose();
    }
    return (
        <>
            <VStack
                divider={<StackDivider />}
                width="100%"
                bgColor="white"
                borderColor="blackAlpha.100"
                borderWidth="1px"
                borderRadius="3px"
                p={5}
                alignItems="start"
                fontWeight='bold'
            >
                <HStack
                    key={'home'}
                    width='100%'
                    height='100%'>
                    <Link to='/' style={{ width: '100%', height: '100%' }} onClick={ClickHandler}>
                        Home
                    </Link>
                </HStack>

                {items.map((item) => {
                    return (
                        <HStack
                            width='100%'
                            height='100%'
                            key={item.name}>
                            {item.dropdownMenu ?
                                <AccordionMenu item={item} onClose={onClose}/> :
                                <Link
                                    to={item.link}
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={ClickHandler}>
                                    {item.name}
                                </Link>
                                }


                        </HStack>


                    )
                })}
            </VStack>
        </>
    )

}