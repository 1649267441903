

function NoMatch() {
    return (
        <main className="layoutPage">
            <h2>このページは存在しません。</h2>
        </main>

    )


}

export default NoMatch;