import { Heading, Image } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { getPostImagePath } from "../../api/WorpressPosts/wordpress";

export const NewsHeaderImage = ({ post }) => {
    const [postImageURL, setPostImageURL] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const isImage = post._links["wp:featuredmedia"] !== undefined ? true : false;
        const postImageApiURL = isImage ? post._links["wp:featuredmedia"][0].href : process.env.REACT_APP_WORDPRESS_DEFAULT_IMAGE_URL;

        // console.log(post._links["wp:featuredmedia"], isImage, postImageApiURL)

        const fetchPostImage = async (url) => {
            let res = await getPostImagePath(url);
            // console.log(res.source_url);
            setPostImageURL(await res);
            setIsLoading(false)
        }
        if (isImage) {
            fetchPostImage(postImageApiURL);
        } else {
            setPostImageURL(postImageApiURL);
            setIsLoading(false);
        }

    }, [post])

    return (

        <>
            {isLoading ? <Heading>Now Loading...</Heading> : <Image src={postImageURL} />}
        </>
    )
}