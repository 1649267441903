import { Text, VStack } from "@chakra-ui/react";
import styled from "styled-components";

const StyledDiv = styled.div`
    width :100vw;
    height:120px;
    margin: 0 calc(50% - 50vw);
    margin-bottom:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-image:url(${({ $imagePath }) => $imagePath});
`;


export default function FirstView({ imagePath, text }) {

    return (
        <StyledDiv $imagePath={imagePath}>
            <VStack
                padding='0 20px'
                backgroundColor='rgba(255, 255, 255, 0.8)'
                alignItems='center'
                borderRadius='10px'
            >
                <Text
                    fontSize='xx-large'
                    fontWeight='bold'
                    fontFamily='serif'

                >
                    {text}</Text>
            </VStack>

        </StyledDiv>
    )
}