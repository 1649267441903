import { useParams } from "react-router-dom";
import { getWordpressPosts } from "../../../api/WorpressPosts/wordpress";
import { useEffect, useState } from "react";
import { HStack, Heading, VStack, Text, Icon, Box } from "@chakra-ui/react";
import { NewsHeaderImage } from "../../../components/news/NewsHeaderImage";
import { FaClockRotateLeft } from "react-icons/fa6";
import { CiClock2 } from "react-icons/ci";
import "./singleNews.css"
import { SetOptionNamesToPost } from "../../../hooks/post/SetOptionNames";

const initialURL = process.env.REACT_APP_WORDPRESS_API_URL;
const categoriesURL = process.env.REACT_APP_WORDPRESS_CATEGORIES_URL;
const tagsURL = process.env.REACT_APP_WORDPRESS_TAGS_URL;

export default function SingleNews() {
    const { newsId } = useParams();
    const postURL = initialURL + '/' + newsId;
    const [post, setPost] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [dates, setDates] = useState({ postDate: '', lastEditDate: "" });
    const [options, setOptions] = useState({ category: '', tag: '' });


    useEffect(() => {
        const fetchPostData = async (url) => {
            const data = await getWordpressPosts(url);
            const categoriesData = await getWordpressPosts(categoriesURL);
            const tagsData = await getWordpressPosts(tagsURL);
            const newPost = await SetOptionNamesToPost(data, categoriesData, tagsData)
            setPost(newPost);
            setIsLoading(false)
            const postDate = newPost.date.slice(0, 10).split('-').join('.');
            const lastEditDate = newPost.modified.slice(0, 10).split('-').join('.');
            setDates({ postDate: postDate, lastEditDate: lastEditDate });

            const category = newPost.categoryNames ? newPost.categoryNames.join(',') : "";
            const tag = newPost.tagNames ? '#' + newPost.tagNames.join('#') : "";
            setOptions({ category: category, tag: tag });

        }
        fetchPostData(postURL);

    }, [newsId])

    // console.log(post, dates)


    return (
        <>
            {isLoading ? <h2>Now Loading...</h2> :

                <>
                    <VStack
                        className="singleNews background-white"
                        width='100%'
                        padding={['5px', '5px', '10px']}
                        display='flex'
                        justifyContent='center'
                        marginBottom={['30px']}

                    >

                        <Heading
                            size='md'
                            marginBottom={['10px']}
                            wordBreak='break-word'>
                            {post.title.rendered}
                        </Heading>
                        <VStack>
                            <NewsHeaderImage post={post} />
                        </VStack>
                        <HStack
                            width='100%'
                            fontSize='sm'
                            alignItems='end'
                            justifyContent='end'
                        >
                            <Text>
                                <Icon
                                    as={FaClockRotateLeft}
                                    verticalAlign='-2.2px'
                                    marginRight='5px' />
                                {dates.lastEditDate}
                            </Text>
                            <Text>
                                <Icon
                                    as={CiClock2}
                                    verticalAlign='-2.2px'
                                    marginRight='5px' />
                                {dates.postDate}
                            </Text>
                        </HStack>
                        <Box w='100%' fontSize='sm' textAlign='right'>
                            <Text>{options.category}</Text>
                            <Text>{options.tag}</Text>
                        </Box>
                        <VStack>

                        </VStack>
                        <div
                            className="article"
                            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                        />
                    </VStack>

                </>
            }


        </>
    );
}
