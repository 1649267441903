import { Box, Text, VStack } from "@chakra-ui/react";
import FirstView from "../..//components/firstView";
import { HeadingLogo } from "../../components/heading/HeadingLogo";
import { GreetingHome } from "../../components/greeting/GreetingHome";
import { NewsList } from "../../components/news/NewsList";
import { TreatmentsLists } from "../../components/treatmenstList/TreatmentsLists";


export default function AboutPage() {
    const StyledText = ({ children, ...rest }) => {
        return (
            <Box
                margin={['50px 0']}
                textAlign='center'
                fontFamily='"Zen Maru Gothic"'
                fontSize={["sm", "lg", "lg"]}
                fontWeight={["medium"]}
                lineHeight={['50px']}
                {...rest}
            >
                {children}

            </Box>

        )
    }

    return (
        <>

            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'About'} />

            <VStack
                className="box-shadow"
                margin={['40px 0px', '40px 0px', '40px 0']}
                backgroundColor='white'
                padding={['30px']}
            >
                <StyledText h="50px">
                    <Text lineHeight={["40px"]}>痛みの根本原因を追求し、本当の治療を、</Text>
                    <Text lineHeight={["40px"]}>経験と情熱であなたの健康を支える接骨院</Text>
                </StyledText>
                <HeadingLogo
                    title={'Greeting'}
                    title_jp={'院長あいさつ'}
                />
                <Box padding={['10px']}>
                    <GreetingHome />
                </Box>


            </VStack>
            <VStack
                padding={['30px']}>
                <HeadingLogo
                    title={'TreatmentMenu'}
                    title_jp={'施術内容'}
                />
                <TreatmentsLists />

            </VStack>
            <VStack
                padding={['30px']}>
                <HeadingLogo
                    title={'News'}
                    title_jp={'お知らせ'}
                />
                <NewsList postNum={3} />

            </VStack>


        </>
    );
}
