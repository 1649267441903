import {
    Breadcrumb,
    BreadcrumbItem,
} from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom';



export default function NavList({ items, defaultColor }) {
    const { pathname } = useLocation();
    
    return (
        <>

            <Breadcrumb
                fontWeight='bold'
                fontSize='sm'
                boxSizing="border-box"
                overflowWrap="wrap"
                maxWidth='100%'
                overflow='auto'
            >
                {items.map((item) => {
                    const opacity = pathname === `/${item.link}` ? 0.3 : 1;
                    
                    return (
                        <BreadcrumbItem
                            key={item.name}
                            color={defaultColor}
                        >
                            
                            <Link
                                className='navLink'
                                to={item.link}
                                style={{ padding: '0 0 0 5px', opacity: opacity }}>
                                {item.name}
                            </Link>
                        </BreadcrumbItem>
                    )
                })}
            </Breadcrumb>
        </>
    )
}