import { Img, Text, VStack, StackDivider, HStack, Link } from "@chakra-ui/react";
import FirstView from "../../components/firstView";
import TellLink from "../..//components/infomation/tel";
import { AddressInfo } from "../../data/Infomations";
import { MapIframe } from "../../components/map/MapIframe";


const ContentBox = ({ children, ...rest }) => {
    return (
        <VStack
            className="contentBox"
            flexBasis={['83%', '70%']}
            width='100%'
            paddingLeft={['10px', '30px']}
            alignItems='start'
            {...rest}>
            {children}
        </VStack>
    )
}
const IndexBox = ({ children, ...rest }) => {
    return (
        <VStack
            className="indexBox"
            flexBasis={['15%', '30%', '30%']}
            paddingLeft={['0px', '50px', '50px']}
            alignItems={['center']}
            justifyContent={['center']}
            {...rest}>
            {children}
        </VStack>
    )
}

const WeekBox = ({ children }) => {
    return (
        <VStack
            className="weekBox"
            flexBasis={['20%']}
            fontSize={["xs", "sm", "md"]}
            alignItems='start'
        >
            {children}
        </VStack>
    )
}

export default function AccessPage() {
    return (
        <>
            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'Access'} />
            <VStack
                className="box-shadow"
                margin={['40px 0px', '40px 0px', '40px 0']}
                backgroundColor='white'
            >
                <VStack>
                    <Img src={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} />
                </VStack>

                <VStack
                    marginTop='30px'
                    padding={['0 10px 30px', '0 10px 30px', '0 30px 30px']}
                    width='100%'
                    divider={<StackDivider />}
                    alignItems="start"
                    fontSize={["sm", "sm", "md"]}
                >
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>住所</Text>
                        </IndexBox>
                        <ContentBox>
                            <Link href='#googleMap'>
                                <Text>
                                    〒{AddressInfo.num}<br /> {AddressInfo.jpn}
                                </Text>
                            </Link>
                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>最寄り</Text>
                        </IndexBox>
                        <ContentBox>
                            <Text>東武東上線 ときわ台駅北口 徒歩8分</Text>
                            <Text>東武東上線 上板橋駅北口 徒歩8分</Text>
                            <Text>国際興行バス 王54 中央通り</Text>

                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>駐輪場</Text>
                        </IndexBox>
                        <ContentBox>
                            <Text>院の前に4台ほど停められるスペース有り</Text>
                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>駐車場</Text>
                        </IndexBox>
                        <ContentBox>
                            <Text>お近くのパーキングをお使い下さい</Text>
                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>TEL</Text>
                        </IndexBox>
                        <ContentBox>
                            <TellLink isTell={false} />
                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox >
                            <Text className="write_vertical">営業時間</Text>
                        </IndexBox>
                        <ContentBox>
                            <HStack w={['100%']}>
                                <WeekBox>
                                    <Text>月・火・金</Text>
                                </WeekBox>
                                <VStack gap={['0']}>
                                    <Text>10:00~14:30(最終受付13:45)</Text>
                                    <Text>16:30~21:00(最終受付20:15)</Text>
                                </VStack>
                            </HStack>
                            <HStack w={['100%']}>
                                <WeekBox>
                                    <Text>水</Text>
                                </WeekBox>
                                <VStack gap={['0']}>
                                    <Text>10:00~14:30(最終受付13:45)</Text>
                                    <Text>16:30~18:00(最終受付17:30)</Text>
                                </VStack>
                            </HStack>
                            <HStack w={['100%']}>
                                <WeekBox>
                                    <Text>土・日</Text>
                                </WeekBox>
                                <VStack gap={['0']}>
                                    <Text>10:00~13:00(最終受付12:45)</Text>
                                    <Text>14:30~17:30(最終受付16:45)</Text>
                                </VStack>
                            </HStack>

                        </ContentBox>
                    </HStack>
                    <HStack w={['100%']}>
                        <IndexBox>
                            <Text>休診日</Text>
                        </IndexBox>
                        <ContentBox>
                            <Text>毎週木曜日・祝日</Text>

                        </ContentBox>
                    </HStack>
                </VStack>
            </VStack >


            <VStack
                id="googleMap"
                className="box-shadow background-white"
                padding={['20px']}>
                <MapIframe />
            </VStack>

        </>
    );
}
