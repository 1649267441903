import { Box, Heading, VStack } from "@chakra-ui/react";
import FirstView from "../..//components/firstView";
import { ServiceFlowInfo } from "../../data/Infomations";
import { ServiceStep } from "../../components/serviceflow/ServiceStep";
import { HeadingLogo } from "../../components/heading/HeadingLogo";
import { TreatmentsLists } from "../../components/treatmenstList/TreatmentsLists";
import { ServiceFlowOverview } from "../../components/serviceflow/ServiceFlowOverview";

export default function ServiceFlowPage() {
    return (
        <>

            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'ServiceFlow'} />
            <VStack
                className="box-shadow"
                w={['100%']}
                p={['30px']}
                gap={['0']}
                backgroundColor={['white']}
            >
                <Heading>ご利用の流れ</Heading>
                <Box
                    zIndex={['2']}
                    p={['40px 0']}
                >

                    <ServiceFlowOverview isLink={true} />
                </Box>
                {ServiceFlowInfo.map((service, id) => {
                    return (<ServiceStep key={id} service={service} id={id} />)
                })}
                <Box
                    margin={['40px 0']}>
                    <HeadingLogo
                        title={'TreatmentMenu'}
                        title_jp={'施術内容'}
                    />
                    <TreatmentsLists />

                </Box>
            </VStack>

        </>
    );
}