import DrawerList from "./drawerList";
import DrawerFooterContents from "./drawerFooter";
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
    useDisclosure,
    Button,
    DrawerCloseButton,
    DrawerHeader,
    DrawerFooter,
    Heading
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useRef } from "react";
import { IconContext } from "react-icons";
import { MenuItems } from "../../data/Infomations";

export default function DrawerMenu() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef();
    return (
        <>
            <Button ref={btnRef} colorScheme='black' onClick={onOpen}>
                <IconContext.Provider value={{ color:'black',size: '1.5em' }}>
                    <GiHamburgerMenu />
                </IconContext.Provider>
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent overflow='auto'>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Heading as='h2'size='lg'>MENU</Heading>
                    </DrawerHeader>
                    <DrawerBody >
                        <DrawerList items={MenuItems} onClose={onClose}/>
                    </DrawerBody>
                    <DrawerFooter>
                        <DrawerFooterContents/>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )

}